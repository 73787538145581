import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import "./App.css";
import OrderNumberSystem from "./OrderNumberSystem";
import OrderNumberInput from "./OrderNumberInput";
import useManageCallNumber from "./useManageCallNumber";

function App() {
    const {
        resetCallNumberArr,
        addOneOrderNoToNumberArr,
        getCallNumberArrFromCookies,
    } = useManageCallNumber();
    const [callNumberArrToDisplay, setCallNumberArrToDisplay] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            const updatedNumbersInStr = getCallNumberArrFromCookies();
            console.warn(updatedNumbersInStr);
            console.warn(callNumberArrToDisplay);
            if (updatedNumbersInStr === '') {
                setCallNumberArrToDisplay([]);
            }

            if (updatedNumbersInStr !== callNumberArrToDisplay.join(",")) {
                let updatedNumbersArr = [];
                updatedNumbersArr = updatedNumbersInStr ? updatedNumbersInStr.split(",") : [];
                setCallNumberArrToDisplay(updatedNumbersArr);
            }
        }, 1000); // Check every second (adjust as needed)

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/display" replace />} />
                <Route
                    path="/keyin"
                    element={
                        <OrderNumberInput
                            resetCallNumberArr={resetCallNumberArr}
                            addOneOrderNoToNumberArr={addOneOrderNoToNumberArr}
                        />
                    }
                />
                <Route
                    path="/display"
                    element={
                        <OrderNumberSystem callNumberArr={callNumberArrToDisplay} />
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
