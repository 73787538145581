import React, { useEffect, useState } from 'react';
import logo from './all-day-coffee-logo.jpg';
import FacebookIcon from '@mui/icons-material/Facebook';
import './OrderNumberSystem.css';

const OrderNumberSystem = ({callNumberArr}) => {
    // Split the numbers into two columns
    const leftColumn = callNumberArr.slice(0, 4);
    const rightColumn = callNumberArr.slice(4, 8);

    return (
        <div className="order-system">
            <div className="current-order">
                <div className="banner">
                    <div className="logo-container">
                        <img src={logo} alt="All Day Coffee Logo" />
                    </div>
                    <div className="facebook-link">
                        <a href="https://www.facebook.com/alldaycoffeehk/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon style={{ fontSize: 40, color: '#fff' }} />
                        </a>
                    </div>
                </div>
                <div className="order-number">
                    {callNumberArr[0] ? callNumberArr[0] : '-'}
                </div>
            </div>
            
            <div className="order-list">
                <h2>以下號碼請到櫃面領取<br/>Orders ready for pickup</h2>
                <div className="order-sublist">
                    <ul style={{ marginRight: '40px' }}>
                        {leftColumn.map((callNumberCandidate, index) => (
                            <li key={index}>{callNumberCandidate}</li>
                        ))}
                    </ul>
                    <ul>
                        {rightColumn.map((callNumberCandidate, index) => (
                            <li key={index}>{callNumberCandidate}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default OrderNumberSystem;