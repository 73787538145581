import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import './OrderNumberInput.css'

const OrderNumberInput = ({resetCallNumberArr, addOneOrderNoToNumberArr}) => {
  const [currentOrderNo, setCurrentOrderNo] = useState('');
  const headingRef = useRef(null);
  const [inputH1Width, setInputH1Width] = useState(100);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (currentOrderNo.trim() !== '') {
        console.log(currentOrderNo)

        setCurrentOrderNo(currentOrderNo.trim());
        addOneOrderNoToNumberArr(currentOrderNo.trim());

        setCurrentOrderNo('')
      }
    }
  };

  const handleReset = () => {
    resetCallNumberArr();
  };

  useEffect(() => {
    if (headingRef && headingRef.current) {
      const h1width = headingRef.current.offsetWidth;
      console.log('Width of h1 element:', h1width);
      setInputH1Width(h1width)
    }
  }, []);

  const inputStyle = {
    width: `${inputH1Width}px`,
    border: '2px solid #000',
    borderRadius: '4px',
    fontSize: '2em',
    fontWeight: 'bold',
    textAlign: 'center',
  }

  return (
    <div className="order-input">
      <h1 ref={headingRef}>輸入叫號號碼</h1>
      <input
        style = {inputStyle}
        type="number"
        value={currentOrderNo}
        onChange={(e) => setCurrentOrderNo(e.target.value)}
        onKeyDown ={handleKeyPress}
        autoFocus
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleReset}
        style={{ marginTop: '20px' }}
      >
        重設叫號
      </Button>
    </div>
  );
};

export default OrderNumberInput;