import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
// custom hook to manage call number
function useManageCallNumber() {
    const [callNumberArr, setCallNumberArr] = useState([]);
    useEffect(() => {
        const callNumberStrAtCookies = Cookies.get("callNumberArr");
        console.warn(callNumberStrAtCookies)
        try {
            const callNumberArrAtCookies = callNumberStrAtCookies.split(',');
            if (callNumberArrAtCookies &&
                Array.isArray(callNumberArrAtCookies)
            ) {
                setCallNumberArr(callNumberArrAtCookies);
            }
        } catch(e) {
            setCallNumberArr([]);
        }
        
    }, []);

    const syncCallNumberArrToCookies = (callNumberArr) => {
        setCallNumberArr(callNumberArr);
        Cookies.set("callNumberArr", callNumberArr);
    }

    const getCallNumberArrFromCookies = () => Cookies.get("callNumberArr");
    
    const addOneOrderNoToNumberArr = (orderNo) => {
        setCallNumberArr([orderNo, ...callNumberArr]);
        syncCallNumberArrToCookies([orderNo, ...callNumberArr]);
    };
    
    const resetCallNumberArr = () => {
        syncCallNumberArrToCookies([])
    }
    
    return {
        callNumberArr,
        resetCallNumberArr,
        addOneOrderNoToNumberArr,
        syncCallNumberArrToCookies,
        getCallNumberArrFromCookies,
    }
}

export default useManageCallNumber;